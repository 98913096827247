import {Component, OnInit} from '@angular/core';
import {LeagueService} from '../../services/league.service';
import {League} from '../../model/league.model';

@Component({
    selector: 'app-teams',
    templateUrl: './teams.component.html',
    styleUrls: ['./teams.component.scss']
})
export class TeamsComponent implements OnInit {

    league: League;

    constructor(private leagueService: LeagueService,
    ) {
    }

    ngOnInit(): void {
        this.setupHeadline();
    }

    private setupHeadline(): void {
        this.league = this.leagueService.selectedLeague;
    }
}
