import { Component, OnInit } from '@angular/core';
import {League} from '../../../model/league.model';
import {Team} from '../../../model/team.model';
import {FormArray, FormBuilder, FormGroup} from '@angular/forms';
import {LanguageService} from '../../../services/language.service';
import {LeagueService} from '../../../services/league.service';
import {TeamService} from '../../../services/team.service';
import {TranslationService} from '../../../services/translation.service';
import {Language} from '../../../model/language.model';
import {Translation} from '../../../model/translation.model';

@Component({
  selector: 'app-teams-list',
  templateUrl: './teams-list.component.html',
  styleUrls: ['./teams-list.component.scss']
})
export class TeamsListComponent implements OnInit {

  league: League;
  teamsList: Team[] = [];
  languageList: Language[] = [];

  teamsForm: FormGroup;

  isAllLanguagesSelected: boolean = false;

  constructor(private formBuilder: FormBuilder,
              private languageService: LanguageService,
              private leagueService: LeagueService,
              private teamService: TeamService,
              private translationService: TranslationService,
  ) {
  }

  ngOnInit(): void {
    this.setupObjects();
    this.setSubscriptions();
    this.getTeamsFromServer();
  }

  private getTeamsFromServer(): void {
    this.teamService.getTeamsFromServer(this.league).then((value) => {
      this.teamsList = value;
      this.updateTeamsTranslations();
    });
  }

  private setupObjects(): void {
    this.teamsForm = this.formBuilder.group({
      leagues: this.formBuilder.array([]),
    });
    this.league = this.leagueService.selectedLeague;
  }

  private setSubscriptions(): void {
    this.teamService.teamsList$.subscribe((teamsList: Team[]) => {
      this.teams().clear();
      this.teamsList = teamsList;
    });
    this.languageService.languageList$.subscribe((languageList: Language[]) => {
      this.languageList = languageList;
      this.isAllLanguagesSelected = this.languageList.filter((e) => e.isSelected).length === this.languageList.length;
      for (const team of this.teamsList) {
        for (let i = 0; i < team.translations.length; i++) {
          team.translations[i].language.isSelected = this.languageList[i].isSelected;
        }
      }
    });
  }

  teams(): FormArray {
    return this.teamsForm.get('leagues') as FormArray;
  }

  private updateTeamsTranslations(): void {
    for (let i = 0; i < this.teamsList.length; i++) {
      this.addTeam(i);
    }
  }

  addTeam(index: number): void {
    this.teams().insert(index, this.newTeam());
    for (let j = 0; j < 38; j++) {
      this.addTeamTranslation(index, j);
    }
    if (this.teamsList.length - 1 === index) {
      for (let y = 0; y < this.teamsList.length; y++) {
        this.translationService.getTeamsTranslationsFromServer(this.teamsList[y], index).then((myIndex) => {
          const wordControlRow = this.teamsForm.get(['leagues', y, 'translations']) as FormArray;
          for (const [j, translation] of this.teamsList[y].translations.entries()) {
            (wordControlRow.at(j) as FormGroup).get('wordControl').patchValue(this.teamsList[y].translations[j].word);
            this.teamsList[y].translations[j].language.isSelected = this.languageList[j].isSelected;
            if (j === this.languageList.length - 1) {
              this.isAllTeamsTranslated();
            }
          }
        });
      }
    }
  }

  newTeam(): FormGroup {
    return this.formBuilder.group({
      teamName: '',
      translations: this.formBuilder.array([])
    });
  }

  addTeamTranslation(teamIndex: number, translationIndex: number): void {
    this.teamTranslations(teamIndex, translationIndex).push(this.newTranslation(''));
    //  }
  }

  teamTranslations(teamIndex: number, x: number): FormArray {
    return this.teams().at(teamIndex).get('translations') as FormArray;
    //   }
  }

  newTranslation(translationWord: string): FormGroup {
    return this.formBuilder.group({
      wordControl: translationWord,
    });
  }

  onSubmit(): void {
    for (const [i, team] of this.teamsList.entries()) {
      const wordControlRow = this.teamsForm.get(['leagues', i, 'translations']) as FormArray;
      for (const [j, translation] of team.translations.entries()) {
        const word: string = wordControlRow.value[j]['wordControl'];
        if (!(word === translation.word)) {
          if (translation.word == null) {
            this.insertWordToServer(word, team, translation.language, translation);
          } else {
            translation.word = word;
            this.updateWordOnServer(word, team, translation);
            this.isAllTeamsTranslated();
          }
        }
      }
    }
  }

  private insertWordToServer(word: string, team: Team, language: Language, translation: Translation): void {
    this.translationService.addTeamTranslationFromServer(word, team, language).then((translationFromServer) => {
      translation.id = translationFromServer.id;
      translation.word = word;
      this.isAllTeamsTranslated();
    });
  }

  private updateWordOnServer(word: string, team: Team, translation: Translation): void {
    this.translationService.updateTeamTranslationFromServer(word, team, translation).then((translationFromServer) => {
      this.isAllTeamsTranslated();
    });
  }

  private isAllTeamsTranslated(): void {
    for (let i = 0; i < this.languageList.length; i++) {
      let isAllTranslated: boolean = true;
      for (let k = 0; k < this.teamsList.length; k++) {
        if (this.teamsList[k].translations[i].word == null || this.teamsList[k].translations[i].word === '') {
          isAllTranslated = false;
          break;
        }
      }
      this.languageList[i].isAllLeaguesTranslated = isAllTranslated;
    }
  }
}
