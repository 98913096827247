import {Injectable} from '@angular/core';
import {LeagueHttp} from '../http/league.http';
import {League} from '../model/league.model';
import {Country} from '../model/country.model';
import {CountryService} from './country.service';
import {Subject} from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class LeagueService {

    selectedCountry: Country;
    leaguesList$: Subject<League[]> = new Subject();
    leaguesList: League[] = [];
    isCup = false;

    selectedLeague$: Subject<League> = new Subject();
    selectedLeague: League;


    constructor(private leagueHttp: LeagueHttp, private countryService: CountryService) {
        this.setupObjects();
        this.getLeagues();
        this._setSubscriptions();
    }

    private setupObjects(): void {
        this.selectedCountry = this.countryService.selectedCountry;
    }

    public getLeagues(): void {
        this.getLeaguesFromServer(this.selectedCountry, this.isCup).then((leaguesList: League[]) => {
            if (this.leaguesList.length > 0) {
                this.leaguesList = [];
            }
            this.leaguesList = leaguesList;
            this.leaguesList$.next(this.leaguesList);
        });
    }

    private _setSubscriptions(): void {
        this.countryService.selectedCountry$.subscribe((country) => {
            this.selectedCountry = country;
            this.getLeagues();
        });
    }

    async getLeaguesFromServer(country: Country, isCup: boolean): Promise<League[]> {
        return new Promise<League[]>((resolve) => {
            this.leagueHttp.getLeaguesFromServer(country, isCup).subscribe((leagues: League[]) => {
                resolve(leagues);
            });
        });
    }

    setSelectedLeague(league: League): void {
      this.selectedLeague = league;
      this.selectedLeague$.next(this.selectedLeague);
    }
}
