import { Component, OnInit } from '@angular/core';
import {Language} from '../../model/language.model';
import {LanguageService} from '../../services/language.service';
import {League} from '../../model/league.model';
import {LeagueService} from '../../services/league.service';

@Component({
  selector: 'app-languages',
  templateUrl: './languages.component.html',
  styleUrls: ['./languages.component.scss']
})
export class LanguagesComponent implements OnInit {

  languagesList: Language[] = [];
  leaguesList: League[] = [];

  constructor(private languageService: LanguageService,
              private leagueService: LeagueService) { }

  ngOnInit(): void {
    this.getLanguagesFromServer();
  }

  getLanguagesFromServer(): void {
    this.languageService.getLanguagesFromServer().then((languages: Language[]) => {
      if (languages != null && languages.length > 0) {
        this.languagesList = languages;
      }
    }, (reject) => {
    });
  }
}
