import { Component, OnInit } from '@angular/core';
import {CountryService} from '../../services/country.service';
import {Country} from '../../model/country.model';
import {LeagueService} from '../../services/league.service';

@Component({
  selector: 'app-leagues',
  templateUrl: './leagues.component.html',
  styleUrls: ['./leagues.component.scss']
})
export class LeaguesComponent implements OnInit {

  countryName: string = '';
  selection: number = 1;

  constructor(private countryService: CountryService,
              private leagueService: LeagueService) { }

  ngOnInit(): void {
    this.selection = this.leagueService.isCup ? 2 : 1;
    this.countryName = this.countryService?.selectedCountry.name ?? '';
    this.setSubscriptions();
  }

  updateSelection(selection: number): void {
    this.selection = selection;
    this.leagueService.isCup = this.selection === 2;
    this.leagueService.getLeagues();
  }

  private setSubscriptions(): void {
    this.countryService.selectedCountry$.subscribe((country: Country) => {
      this.countryName = country.name;
    });
  }
}
