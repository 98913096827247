import {Injectable} from '@angular/core';
import {Country} from '../model/country.model';
import {BehaviorSubject, Subject} from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class CountryService {
    selectedCountry$: Subject<Country> = new Subject();
    selectedCountry: Country = new Country();

    updateCountrySelection(country: Country): void {
        this.selectedCountry = country;
        this.selectedCountry$.next(this.selectedCountry);
    }
}
