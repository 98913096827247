import {Injectable} from '@angular/core';
import {LanguageHttp} from '../http/language.http';
import {Language} from '../model/language.model';
import {Subject} from 'rxjs';
import {League} from '../model/league.model';

@Injectable({
    providedIn: 'root',
})
export class LanguageService {

    languageList$: Subject<Language[]> = new Subject();
    languageList: Language[] = [];

    constructor(private languageHttp: LanguageHttp) {
    }

    async getLanguagesFromServer(): Promise<Language[]> {
        return new Promise<Language[]>((resolve) => {
            this.languageHttp.getLanguagesFromServer().subscribe((languages: Language[]) => {
                this.languageList = languages;
                this.languageList$.next(this.languageList);
                resolve(languages);
            });
        });
    }

    updateLanguageSelection(languageIndex: number, isSelected: boolean): void {
        this.languageList[languageIndex].isSelected = isSelected;
        this.languageList$.next(this.languageList);
    }
}
