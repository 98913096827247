import { Component, OnInit } from '@angular/core';
import {Language} from '../../../model/language.model';
import {LanguageService} from '../../../services/language.service';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-languages-selections',
  templateUrl: './languages-selections.component.html',
  styleUrls: ['./languages-selections.component.scss']
})
export class LanguagesSelectionsComponent implements OnInit {

  languagesList: Language[] = [];

  languagesFormControl: FormGroup;

  isFirstTime: boolean = true;

  numberOfTimes = 0;
  initialArraySelectedLength = 0;

  constructor(
      private languageService: LanguageService,
      private formBuilder: FormBuilder) {
  }

  ngOnInit(): void {
    this.setupObjects();
    this.setupFormControl();
    this.setupSubscriptions();
  }

  private setupObjects(): void {
    this.languagesList = this.languageService.languageList;
  }

  private setupFormControl(): void {
    this.languagesFormControl = this.formBuilder.group({
      languageName: ['', [Validators.required]],
    });
    //this.languagesFormControl = new FormControl(this.languagesList.map((e) => e.name));


  }

  private setupSubscriptions(): void {
     const subscription: Subscription = this.languageService.languageList$.subscribe((languagesList: Language[]) => {
      this.languagesList = languagesList;
      const defaultValues = this.languagesList.filter((e) => e.isSelected);
      this.languagesFormControl.controls['languageName'].setValue(defaultValues);
      this.initialArraySelectedLength = defaultValues.length;
      subscription.unsubscribe();
    });
  }

  onSelectionChange(language: Language): void {
    if (this.numberOfTimes >= this.initialArraySelectedLength) {
      const languageIndex: number = this.languagesList.findIndex((e) => e === language);
      this.languagesList.find((e) => e === language).isSelected = !this.languagesList.find((e) => e === language).isSelected;
      this.languageService.updateLanguageSelection(languageIndex, this.languagesList.find((e) => e === language).isSelected);
    }
    else {
      this.numberOfTimes++;
    }
  }
}
