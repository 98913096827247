import {Injectable} from '@angular/core';
import {LeagueHttp} from '../http/league.http';
import {League} from '../model/league.model';
import {Country} from '../model/country.model';
import {TranslationHttp} from '../http/translation.http';
import {Translation} from '../model/translation.model';
import {Language} from '../model/language.model';
import {Team} from '../model/team.model';

@Injectable({
    providedIn: 'root',
})
export class TranslationService {

    constructor(private translationHttp: TranslationHttp) {
    }

    async getLeaguesTranslationsFromServer(league: League, index: number): Promise<number> {
        return new Promise<number>((resolve) => {
            this.translationHttp.getLeaguesTranslationsFromServer(league, index).subscribe((responseIndex) => {
                resolve(responseIndex);
            });
        });
    }

    async getTeamsTranslationsFromServer(team: Team, index: number): Promise<number> {
        return new Promise<number>((resolve) => {
            this.translationHttp.getTeamsTranslationsFromServer(team, index).subscribe((responseIndex) => {
                resolve(responseIndex);
            });
        });
    }

    async addLeagueTranslationFromServer(word: string, league: League, language: Language): Promise<Translation> {
        return new Promise<Translation>((resolve) => {
            this.translationHttp.addLeagueTranslationFromServer(word, league, language).subscribe((translation) => {
                resolve(translation);
            });
        });
    }

    async updateTranslationFromServer(word: string, league: League, translation: Translation): Promise<boolean> {
        return new Promise<boolean>((resolve) => {
            this.translationHttp.updateLeagueTranslationFromServer(word, league, translation).subscribe((isSuccess) => {
                resolve(isSuccess);
            });
        });
    }

    async addTeamTranslationFromServer(word: string, team: Team, language: Language): Promise<Translation> {
        return new Promise<Translation>((resolve) => {
            this.translationHttp.addTeamTranslationFromServer(word, team, language).subscribe((translation) => {
                resolve(translation);
            });
        });
    }

    async updateTeamTranslationFromServer(word: string, team: Team, translation: Translation): Promise<boolean> {
        return new Promise<boolean>((resolve) => {
            this.translationHttp.updateTeamTranslationFromServer(word, team, translation).subscribe((isSuccess) => {
                resolve(isSuccess);
            });
        });
    }
}
