<div class="country-picker">
    <app-country-picker></app-country-picker>
</div>
<div *ngIf="countryName.length > 0">
    <h2>{{countryName}}</h2>
    <div class="btn-group" role="group">
        <button
                (click)="updateSelection(1)"
                [ngClass]="selection == 1 ? 'primary-color' : 'btn btn-secondary'"
                type="button">
            Leagues
        </button>
        <button
                (click)="updateSelection(2)"
                [ngClass]="selection == 2 ? 'primary-color' : 'btn btn-secondary'"
                type="button">
            Tournaments
        </button>
    </div>
    <app-languages-selections></app-languages-selections>
    <div class="language">
        <app-languages></app-languages>
    </div>
    <app-league-list></app-league-list>
</div>
