import {Injectable} from '@angular/core';
import {LeagueHttp} from '../http/league.http';
import {League} from '../model/league.model';
import {Country} from '../model/country.model';
import {CountryService} from './country.service';
import {Subject} from 'rxjs';
import {Team} from '../model/team.model';
import {TeamHttp} from '../http/team.http';

@Injectable({
    providedIn: 'root',
})
export class TeamService {

    teamsList$: Subject<Team[]> = new Subject();
    teamsList: Team[] = [];

    constructor(private teamHttp: TeamHttp) {
    }

    async getTeamsFromServer(league: League): Promise<Team[]> {
        return new Promise<Team[]>((resolve) => {
            this.teamHttp.getTeamsFromServer(league).subscribe((teamsList: Team[]) => {
                resolve(teamsList);
            });
        });
    }
}
