<div *ngIf="teamsList != null && teamsList.length > 0" class="root">
    <form [formGroup]="teamsForm" (ngSubmit)="onSubmit()">
        <div formArrayName="leagues">
            <div *ngFor="let team of teams().controls; let teamIndex=index" style="display: flex;">
                <div [formGroupName]="teamIndex" class="word">
                    <h6>{{this.teamsList[teamIndex].name.trim()}}</h6>
                    <div formArrayName="translations" class="word" [ngClass]="isAllLanguagesSelected ? 'word' : 'empty-cell'">
                        <div *ngFor="let translation of teamTranslations(teamIndex, -1).controls; let translationIndex=index">
                            <div [formGroupName]="translationIndex"
                                 [ngClass]="teamsList[teamIndex].translations[translationIndex] == null ||
                             teamsList[teamIndex].translations[translationIndex].language.isSelected ? 'word' : 'none'"
                                 class="word">
                                <input type="text" formControlName="wordControl" style="margin-left: 30px"
                                       [ngStyle]="{'background-color':  teamsList[teamIndex].translations[translationIndex] == null
                                   || teamsList[teamIndex].translations[translationIndex].word == null ||
                                    teamsList[teamIndex].translations[translationIndex].word === ''  ? 'white' : languageList[translationIndex].isAllLeaguesTranslated ? 'lightgreen' : 'lightgray'}"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <button class="submit btn" type="submit">Update</button>
    </form>
</div>


