<form id="form" [formGroup]="languagesFormControl">
<mat-form-field appearance="fill">
    <mat-label>languages</mat-label>
    <mat-select [formControlName]="'languageName'" multiple>
        <mat-option
                (onSelectionChange)="(onSelectionChange(language))"
                *ngFor="let language of languagesList" [value]="language">
            {{language.name}}
        </mat-option>
    </mat-select>
</mat-form-field>
</form>
