<div *ngIf="leaguesList != null && leaguesList.length > 0" class="root">
    <form [formGroup]="leaguesForm" (ngSubmit)="onSubmit()">
        <div formArrayName="leagues">
            <div *ngFor="let league of leagues().controls; let leagueIndex=index" style="display: flex;">
                <div [formGroupName]="leagueIndex" class="word">
                    <h5 (click)="onLeague(this.leaguesList[leagueIndex])">{{this.leaguesList[leagueIndex].name.trim()}}</h5>
                    <div formArrayName="translations" [ngClass]="isAllLanguagesSelected ? 'word' : 'empty-cell'">
                        <div *ngFor="let translation of leagueTranslations(leagueIndex, -1).controls; let translationIndex=index">
                            <div [formGroupName]="translationIndex"
                                 [ngClass]="leaguesList[leagueIndex].translations[translationIndex] == null ||
                             leaguesList[leagueIndex].translations[translationIndex].language.isSelected ? 'word' : 'none'"
                                 class="word">
                                <input type="text" formControlName="wordControl" style="margin-left: 30px"
                                       [ngStyle]="{'background-color':  leaguesList[leagueIndex].translations[translationIndex] == null
                                   || leaguesList[leagueIndex].translations[translationIndex].word == null ||
                                    leaguesList[leagueIndex].translations[translationIndex].word === ''  ? 'white' : languageList[translationIndex].isAllLeaguesTranslated ? 'lightgreen' : 'lightgray'}"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <button class="submit btn" type="submit">Update</button>
    </form>
</div>


