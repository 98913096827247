import {Component, OnInit} from '@angular/core';
import {League} from '../../../model/league.model';
import {Language} from '../../../model/language.model';
import {FormArray, FormBuilder, FormGroup} from '@angular/forms';
import {LanguageService} from '../../../services/language.service';
import {LeagueService} from '../../../services/league.service';
import {CountryService} from '../../../services/country.service';
import {TranslationService} from '../../../services/translation.service';
import {Translation} from '../../../model/translation.model';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
    selector: 'app-league-list',
    templateUrl: './league-list.component.html',
    styleUrls: ['./league-list.component.scss']
})
export class LeagueListComponent implements OnInit {

    leaguesList: League[] = [];
    languageList: Language[] = [];

    leaguesForm: FormGroup;

    isAllLanguagesSelected: boolean = false;

    constructor(private formBuilder: FormBuilder,
                private languageService: LanguageService,
                private leagueService: LeagueService,
                private countryService: CountryService,
                private translationService: TranslationService,
                private router: Router,
                private activatedRoute: ActivatedRoute) {
    }

    ngOnInit(): void {
        this.setupObjects();
        this.setSubscriptions();
    }

    private setupObjects(): void {
        this.leaguesForm = this.formBuilder.group({
            leagues: this.formBuilder.array([]),
        });
        if (this.leagueService.leaguesList != null && this.leagueService.leaguesList.length > 0) {
            this.leaguesList = this.leagueService.leaguesList;
            this.updateLeaguesTranslations();
        }
    }

    private setSubscriptions(): void {
        this.leagueService.leaguesList$.subscribe((leaguesList: League[]) => {
            this.leagues().clear();
            this.leaguesList = leaguesList;
            this.updateLeaguesTranslations();
        });
        this.languageService.languageList$.subscribe((languageList: Language[]) => {
            this.languageList = languageList;
            this.isAllLanguagesSelected = this.languageList.filter((e) => e.isSelected).length === this.languageList.length;
            for (const league of this.leaguesList) {
                for (let i = 0; i < league.translations.length; i++) {
                    league.translations[i].language.isSelected = this.languageList[i].isSelected;
                }
            }
        });
    }

    private updateLeaguesTranslations(): void {
        for (let i = 0; i < this.leaguesList.length; i++) {
            this.addLeague(i);
        }
    }

    leagues(): FormArray {
        return this.leaguesForm.get('leagues') as FormArray;
    }

    newLeague(): FormGroup {
        return this.formBuilder.group({
            leagueName: '',
            translations: this.formBuilder.array([])
        });
    }

    addLeague(index: number): void {
        this.leagues().insert(index, this.newLeague());
        for (let j = 0; j < 38; j++) {
            this.addLeagueTranslation(index, j);
        }
        if (this.leaguesList.length - 1 === index) {
            for (let y = 0; y < this.leaguesList.length; y++) {
                this.translationService.getLeaguesTranslationsFromServer(this.leaguesList[y], index).then((myIndex) => {
                    const wordControlRow = this.leaguesForm.get(['leagues', y, 'translations']) as FormArray;
                    for (const [j, translation] of this.leaguesList[y].translations.entries()) {
                        (wordControlRow.at(j) as FormGroup).get('wordControl').patchValue(this.leaguesList[y].translations[j].word);
                        this.leaguesList[y].translations[j].language.isSelected = this.languageList[j].isSelected;
                        if (j === this.languageList.length - 1) {
                            this.isAllLeaguesTranslated();
                        }
                    }
                });
            }
        }
    }

    private isAllLeaguesTranslated(): void {
        for (let i = 0; i < this.languageList.length; i++) {
            let isAllTranslated: boolean = true;
            for (let k = 0; k < this.leaguesList.length; k++) {
                if (this.leaguesList[k].translations[i].word == null || this.leaguesList[k].translations[i].word === '') {
                    isAllTranslated = false;
                    break;
                }
            }
            this.languageList[i].isAllLeaguesTranslated = isAllTranslated;
        }
    }

    leagueTranslations(leagueIndex: number, x: number): FormArray {
        // if (this.leagues().at(leagueIndex) !== undefined) {
        return this.leagues().at(leagueIndex).get('translations') as FormArray;
        //   }
    }

    newTranslation(translationWord: string): FormGroup {
        return this.formBuilder.group({
            wordControl: translationWord,
        });
    }

    addLeagueTranslation(leagueIndex: number, translationIndex: number): void {
        this.leagueTranslations(leagueIndex, translationIndex).push(this.newTranslation(''));
    }

    onSubmit(): void {
        for (const [i, league] of this.leaguesList.entries()) {
            const wordControlRow = this.leaguesForm.get(['leagues', i, 'translations']) as FormArray;
            for (const [j, translation] of league.translations.entries()) {
                const word: string = wordControlRow.value[j]['wordControl'];
                if (!(word === translation.word)) {
                    if (translation.word == null) {
                        this.insertWordToServer(word, league, translation.language, translation);
                    } else {
                        translation.word = word;
                        this.updateWordOnServer(word, league, translation);
                    }
                }
            }
        }
    }

    private insertWordToServer(word: string, league: League, language: Language, translation: Translation): void {
        this.translationService.addLeagueTranslationFromServer(word, league, language).then((translationFromServer) => {
            translation.id = translationFromServer.id;
            translation.word = word;
            this.isAllLeaguesTranslated();
        });
    }

    private updateWordOnServer(word: string, league: League, translation: Translation): void {
        this.translationService.updateTranslationFromServer(word, league, translation).then((translationFromServer) => {
            this.isAllLeaguesTranslated();
        });
    }

    onLeague(league: League): void {
        this.leagueService.selectedLeague = league;
        this.router.navigate(['/teams'], {relativeTo: this.activatedRoute}).then(() => {

        });
    }
}
