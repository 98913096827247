import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppComponent} from './app.component';
import {NoopAnimationsModule} from '@angular/platform-browser/animations';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {ReactiveFormsModule} from '@angular/forms';
import { LeaguesComponent } from './league/leagues/leagues.component';
import { CountryPickerComponent } from './country/country-picker/country-picker.component';
import { LeagueListComponent } from './league/leagues/league-list/league-list.component';
import {HttpClientModule} from '@angular/common/http';
import { LanguagesComponent } from './language/languages/languages.component';
import { TeamsComponent } from './team/teams/teams.component';
import {AppRoutingModule} from './app-routing.modules';
import { TeamsListComponent } from './team/teams/teams-list/teams-list.component';
import { LanguagesSelectionsComponent } from './language/languages/languages-selections/languages-selections.component';
import {MatSelectModule} from '@angular/material/select';

@NgModule({
    declarations: [
        AppComponent,
        LeaguesComponent,
        CountryPickerComponent,
        LeagueListComponent,
        LanguagesComponent,
        TeamsComponent,
        TeamsListComponent,
        LanguagesSelectionsComponent,
    ],
    imports: [
        AppRoutingModule,
        BrowserModule,
        NoopAnimationsModule,
        MatAutocompleteModule,
        ReactiveFormsModule,
        HttpClientModule,
        MatSelectModule,
    ],
    providers: [],
    bootstrap: [AppComponent]
})
export class AppModule {
}
