import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Language} from '../model/language.model';
import {API_URL} from '../constants/url.constants';
import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {League} from '../model/league.model';
import {Country} from '../model/country.model';
import {element} from 'protractor';

@Injectable({
    providedIn: 'root',
})
export class LeagueHttp {
    constructor(private http: HttpClient) {}

    getLeaguesFromServer(country: Country, isCup: boolean): Observable<League[]> {
        const formData = new FormData();
        formData.append('leagueCountryCode', country.code);
        formData.append('leagueIsCup', isCup ? '1' : '0');
        const apiUrl = `${API_URL}league/get-leagues.php`;
        return this.http.post(apiUrl, formData).pipe(
            map(responseData => {
                const responseArray = responseData as [];
                const leagues: League[] = responseArray.map(element => element['league']);
                leagues.map((_) => _.translations = []);
                return leagues;
            }));
    }
}
