import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import {AppComponent} from './app.component';
import {TeamsComponent} from './team/teams/teams.component';
import {LeaguesComponent} from './league/leagues/leagues.component';

const appRoutes: Routes = [
    {path: '', component: LeaguesComponent},
    {path: 'teams', component: TeamsComponent},
];

@NgModule({
    imports: [
        RouterModule.forRoot(appRoutes)
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
