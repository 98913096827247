import { Component, OnInit } from '@angular/core';
import {FormControl} from '@angular/forms';
import {Country} from '../../model/country.model';
import countryJson from '../../../assets/country/country-list.json';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {CountryService} from '../../services/country.service';

@Component({
  selector: 'app-country-picker',
  templateUrl: './country-picker.component.html',
  styleUrls: ['./country-picker.component.scss']
})
export class CountryPickerComponent implements OnInit {
  formControl = new FormControl();
  countriesList: Country[] = countryJson.map(country => country);
  filteredOptions: Observable<Country[]>;

  constructor(private countryService: CountryService) {}

  ngOnInit(): void {
    this.filteredOptions = this.formControl.valueChanges.pipe(
        startWith(''),
        map(value => this._filter(value))
    );
  }

  private _filter(value: string): Country[] {
    const filterValue = value.toLowerCase();
    return this.countriesList.filter(a => a.name.toLowerCase().includes(filterValue));
  }

  onOptionSelected(countryName: string): void {
    const country = this.countriesList.filter((element) => element.name === countryName)[0];
    this.countryService.updateCountrySelection(country);
  }
}
