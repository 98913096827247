import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {API_URL} from '../constants/url.constants';
import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {League} from '../model/league.model';
import {Team} from '../model/team.model';

@Injectable({
    providedIn: 'root',
})
export class TeamHttp {
    constructor(private http: HttpClient) {}

    getTeamsFromServer(league: League): Observable<Team[]> {
        const formData = new FormData();
        formData.append('teamLeagueId', league.id.toString());
        const apiUrl = `${API_URL}team/get-teams.php`;
        return this.http.post(apiUrl, formData).pipe(
            map(responseData => {
                const responseArray = responseData as [];
                const teamsList: Team[] = responseArray.map(e => e['team']);
                teamsList.map((_) => _.translations = []);
                return teamsList;
            }));
    }
}
