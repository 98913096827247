import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {API_URL} from '../constants/url.constants';
import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {League} from '../model/league.model';
import {Country} from '../model/country.model';
import {Translation} from '../model/translation.model';
import {Language} from '../model/language.model';
import {Team} from '../model/team.model';

@Injectable({
    providedIn: 'root',
})
export class TranslationHttp {
    constructor(private http: HttpClient) {}

    getLeaguesTranslationsFromServer(league: League, leagueIndex: number): Observable<number> {
        const formData = new FormData();
        formData.append('leagueTranslationLeagueId', league.id.toString());
        const apiUrl = `${API_URL}translation/league/get-leagues-translations.php`;
        return this.http.post(apiUrl, formData).pipe(
            map(responseData => {
                const responseArray = responseData as [];
                const translations: Translation[] = responseArray.map(element => element['translation']);
                league.translations = translations;
                return leagueIndex;
            }));
    }

    getTeamsTranslationsFromServer(team: Team, teamIndex: number): Observable<number> {
        const formData = new FormData();
        formData.append('teamTranslationTeamId', team.id.toString());
        const apiUrl = `${API_URL}translation/team/get-teams-translations.php`;
        return this.http.post(apiUrl, formData).pipe(
            map(responseData => {
                const responseArray = responseData as [];
                const translations: Translation[] = responseArray.map(element => element['translation']);
                team.translations = translations;
                return teamIndex;
            }));
    }

    addLeagueTranslationFromServer(word: string, league: League, language: Language): Observable<Translation> {
        const formData = new FormData();
        formData.append('leagueTranslationWord', word);
        formData.append('leagueTranslationLeagueId', league.id.toString());
        formData.append('leagueTranslationLanguageId', language.id.toString());
        const apiUrl = `${API_URL}translation/league/add-league-translation.php`;
        return this.http.post(apiUrl, formData).pipe(
            map(responseData => {
                const responseArray = responseData as [];
                const translations: Translation[] = responseArray.map(element => element['translation']);
                return translations[0];
            }));
    }

    addTeamTranslationFromServer(word: string, team: Team, language: Language): Observable<Translation> {
        const formData = new FormData();
        formData.append('teamTranslationWord', word);
        formData.append('teamTranslationTeamId', team.id.toString());
        formData.append('teamTranslationLanguageId', language.id.toString());
        const apiUrl = `${API_URL}translation/team/add-team-translation.php`;
        return this.http.post(apiUrl, formData).pipe(
            map(responseData => {
                const responseArray = responseData as [];
                const translations: Translation[] = responseArray.map(element => element['translation']);
                return translations[0];
            }));
    }

    updateLeagueTranslationFromServer(word: string, league: League, translation): Observable<boolean> {
        const formData = new FormData();
        formData.append('leagueTranslationId', translation.id.toString());
        formData.append('leagueTranslationWord', word);
        formData.append('leagueTranslationLeagueId', league.id.toString());
        formData.append('leagueTranslationLanguageId', translation.language.id.toString());
        const apiUrl = `${API_URL}translation/league/update-league-translation.php`;
        return this.http.post(apiUrl, formData).pipe(
            map(responseData => {
                //const responseArray = responseData as [];
                //const translations: Translation[] = responseArray.map(element => element['translation']);
                return true;
            }));
    }

    updateTeamTranslationFromServer(word: string, team: Team, translation): Observable<boolean> {
        const formData = new FormData();
        formData.append('teamTranslationId', translation.id.toString());
        formData.append('teamTranslationWord', word);
        formData.append('teamTranslationTeamId', team.id.toString());
        formData.append('teamTranslationLanguageId', translation.language.id.toString());
        const apiUrl = `${API_URL}translation/team/update-team-translation.php`;
        return this.http.post(apiUrl, formData).pipe(
            map(responseData => {
                //const responseArray = responseData as [];
                //const translations: Translation[] = responseArray.map(element => element['translation']);
                return true;
            }));
    }
}
