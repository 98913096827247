<div class="form-group">
    <input type="text"
           class="form-control"
           placeholder="Select country"
           aria-label="Number"
           matInput
           [formControl]="formControl"
           [matAutocomplete]="auto">
</div>
<mat-autocomplete autoActiveFirstOption #auto="matAutocomplete"
                  (optionSelected)="onOptionSelected($event.option.value)">
    <mat-option *ngFor="let country of filteredOptions | async" [value]="country.name">
        {{country.name}}
    </mat-option>
</mat-autocomplete>
