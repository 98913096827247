import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Language} from '../model/language.model';
import {API_URL} from '../constants/url.constants';
import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class LanguageHttp {
    constructor(private http: HttpClient) {}

    getLanguagesFromServer(): Observable<Language[]> {
        const formData = new FormData();
        const apiUrl = `${API_URL}language/get-languages.php`;
        return this.http.post(apiUrl, formData).pipe(
            map(responseData => {
                const responseArray = responseData as [];
                const languages: Language[] = responseArray.map(a => a['language']);
                return languages;
            }));
    }
}
