import {Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {FormControl} from '@angular/forms';
import {map, startWith} from 'rxjs/operators';
import countryJson from '../assets/country/country-list.json';
import {Country} from './model/country.model';
import {League} from './model/league.model';
import {Language} from './model/language.model';
import {Translation} from './model/translation.model';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

}
